/* Scrolling to the right */
@keyframes scrollRight {
    0% {
      transform: translateX(3000);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Scrolling to the left */
  @keyframes scrollLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(3000);
    }
  }
  
  .scrolling-row-right {
    display: flex;
    animation: scrollRight 40s linear infinite;
  }
  
  .scrolling-row-left {
    display: flex;
    animation: scrollLeft 40s linear infinite;
  }
  
  img {
    flex-shrink: 0;
  }
  